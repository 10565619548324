<template>
    <cms-block v-bind="$props" @block-selected="selectBlock" container-class="card" no-padding
        :block-component="CmsLink" :block-component-params="{link: currentLink}">
        <cms-component-image comp-id="image" :component="block.image" v-bind="componentProps"/>
        <b-card-body>
            <cms-component-glyph comp-id="glyph" :component="block.glyph" v-bind="componentProps"/>
            <cms-component-text comp-id="title" :component="block.title" v-bind="componentProps"/>
            <cms-component-text comp-id="subtitle" :component="block.subtitle" v-bind="componentProps"/>
            <cms-component-text comp-id="description" :component="block.description" v-bind="componentProps"/>
        </b-card-body>
        <b-card-footer v-if="block.altButton.isVisible() || block.mainButton.isVisible()" class="actions w-100">
            <cms-component-button comp-id="altButton" :component="block.altButton" v-bind="componentProps"/>
            <cms-component-button comp-id="mainButton" :component="block.mainButton" v-bind="componentProps"/>
        </b-card-footer>
    </cms-block>
</template>

<script>
import CmsLink from "@/components/cms/cms-link.vue";
import CmsBlock from "@/components/editor/rendering/blocks/cms-block.vue";
import CmsComponentButton from "@/components/editor/rendering/components/cms-component-button.vue";
import CmsComponentGlyph from "@/components/editor/rendering/components/cms-component-glyph.vue";
import CmsComponentImage from "@/components/editor/rendering/components/cms-component-image.vue";
import CmsComponentText from "@/components/editor/rendering/components/cms-component-text.vue";

// cms-block-card #hot-reload-debug
export default {
    name: `cms-block-card`,
    components: {CmsComponentGlyph, CmsLink, CmsComponentImage, CmsComponentButton, CmsComponentText, CmsBlock},
    extends: CmsBlock,
    data() {
        return {
            CmsLink
        }
    },
    computed: {
        currentLink() {
            let link;
            if (this.block.link.useBinding && this.boundItem && this.block.link.linkField) {
                link = this.boundItem.getField(this.block.link.linkField);
            }
            if (!link) {
                link = this.block.link.link;
            }
            return link;
        }
    }
}
</script>

<style lang="scss" scoped>
.cms-block-card {
    display: grid;

    .card-body {
        padding: var(--block-padding-top) var(--block-padding-right) var(--block-padding-bottom) var(--block-padding-left);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .card-footer {
        display: flex;
        align-items: center;

        .cms-component-button {
            vertical-align: middle;
            margin-top: 0;

            & + .cms-component-button {
                margin-left: var(--block-spacing);
            }
        }
    }
}
</style>
